<style scoped>
    .calendar{
        width: 100%;
        position: relative;
        overflow: hidden;
    }
    .title{
        width: 7.19rem;
        height: 1.63rem;
        background: url('https://image.xiaoxxx.cn/test/urlGather/2024071669c8c7ccad24fc18127927.png') no-repeat center;
        background-size: contain;
        position: absolute;
        left: 0;
        top: 1.5rem;
    }
    .content{
        width: 100%;
        height: 9.57rem;
        background: url('https://image.xiaoxxx.cn/test/urlGather/2024071555d0514622bc12ea382522.png') no-repeat center;
        background-size: contain;
        position: absolute;
        left: 0;
        top: 3rem;
        box-sizing: border-box;
        padding: 0.8rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
    .calendar-list{
        width: 1.79rem;
        height: 2.01rem;
        margin: 0 0.1rem;
    }
    .calendar-list img{
        display: block;
        width: 100%;
    }
    .mask{
        width: 100%;
        height: 16.24rem;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 10000;
    }
    .mask-content{
        width: 6.02rem;
        height: 9.32rem;
        background: url('https://image.xiaoxxx.cn/test/urlGather/20240715aa02ceb12c28b7a8807322.png') no-repeat center;
        background-size: contain;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    }
    .popup-title{
        height: 0.68rem;
        position: absolute;
        left: 0.4rem;
        top: 0.6rem;
    }
    .popup-con{
        position: absolute;
        left: 0;
        top: 1.33rem;
    }
    .close{
        width: 1.2rem;
        height: 0.48rem;
        background: url('https://image.xiaoxxx.cn/test/urlGather/2024071668249ee65aa77a77579792.png') no-repeat center;
        background-size: contain;
        position: absolute;
        right: 0rem;
        top: 1.5rem;
    }
</style>
<template>
    <div class="calendar main">
        <div class="title"></div>
        <div class="content">
            <div class="calendar-list" v-for="item in calendarList" :key="item.index" @click="handleCalendar(item)">
                <img :src="item.url" alt="">
            </div>
        </div>
        <div class="mask" v-if="isCalendarPopup" @click="closeCalendarPopup">
            <div class="mask-content" @click.stop>
                <img :style="`width: ${itemPopup.titlewidth};`" :src="itemPopup.popuptitle" alt="" class="popup-title">
                <img :style="`width: ${itemPopup.conwidth};height: ${itemPopup.conheight};`" :src="itemPopup.popupcon" alt="" class="popup-con">
                <div class="close" @click="closeCalendarPopup"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default{
    data(){
        return{
            isCalendarPopup:false,
            itemPopup:{},
            calendarList:[
                {index:0,url:'https://image.xiaoxxx.cn/test/urlGather/20240715596e6524d85c7853775912.png',popuptitle:'https://image.xiaoxxx.cn/test/urlGather/20240716318a1a0aa2b62226193964.png',titlewidth:'5.61rem',popupcon:'https://image.xiaoxxx.cn/test/urlGather/20240716f51722a8d130f211874540.png',conwidth:'5.32rem',conheight:'5.79rem'},
                {index:1,url:'https://image.xiaoxxx.cn/test/urlGather/202407159e56bdabede4b94a592723.png',popuptitle:'https://image.xiaoxxx.cn/test/urlGather/20240716c62a94d9710ec76f940855.png',titlewidth:'5.64rem',popupcon:'https://image.xiaoxxx.cn/test/urlGather/20240716ecea378b4b3a10f8849633.png',conwidth:'5.32rem',conheight:'7.47rem'},
                {index:2,url:'https://image.xiaoxxx.cn/test/urlGather/202407157b15bb3f17961ea0414698.png',popuptitle:'https://image.xiaoxxx.cn/test/urlGather/20240716bae78113f44af194955913.png',titlewidth:'5.37rem',popupcon:'https://image.xiaoxxx.cn/test/urlGather/202407167f9fce7b358d093b758585.png',conwidth:'5.32rem',conheight:'5.76rem'},
                {index:3,url:'https://image.xiaoxxx.cn/test/urlGather/202407154592fe5a9ec7eedc597132.png',popuptitle:'https://image.xiaoxxx.cn/test/urlGather/2024071663c30ea4568da6ac271504.png',titlewidth:'5.25rem',popupcon:'https://image.xiaoxxx.cn/test/urlGather/20240716b7848c38bc2a233c376395.png',conwidth:'5.32rem',conheight:'7.53rem'},
                {index:4,url:'https://image.xiaoxxx.cn/test/urlGather/2024071549deff16bc2a010b110410.png',popuptitle:'https://image.xiaoxxx.cn/test/urlGather/2024071645f500f0a1cfcd7f730658.png',titlewidth:'5.04rem',popupcon:'https://image.xiaoxxx.cn/test/urlGather/20240716602e2cfb956ecc08371418.png',conwidth:'5.8rem',conheight:'7.1rem'},
                {index:5,url:'https://image.xiaoxxx.cn/test/urlGather/20240715f64f0ff8f920c03e593205.png',popuptitle:'https://image.xiaoxxx.cn/test/urlGather/20240716396debd7cba1991a564214.png',titlewidth:'5.14rem',popupcon:'https://image.xiaoxxx.cn/test/urlGather/202407166ec808a15d490a73454416.png',conwidth:'5.23rem',conheight:'7.22rem'},
                {index:6,url:'https://image.xiaoxxx.cn/test/urlGather/202407154d71559349a58a90728129.png',popuptitle:'https://image.xiaoxxx.cn/test/urlGather/20240716baacf11458743b5e134276.png',titlewidth:'5.12rem',popupcon:'https://image.xiaoxxx.cn/test/urlGather/2024071615460387116da5ea104170.png',conwidth:'5.23rem',conheight:'3.74rem'},
                {index:7,url:'https://image.xiaoxxx.cn/test/urlGather/20240715067bbedc948761ae975218.png',popuptitle:'https://image.xiaoxxx.cn/test/urlGather/20240716519623e1853f0870827577.png',titlewidth:'5.5rem',popupcon:'https://image.xiaoxxx.cn/test/urlGather/20240716d23982540785afff400900.png',conwidth:'5.23rem',conheight:'5.62rem'},
                {index:8,url:'https://image.xiaoxxx.cn/test/urlGather/202407152da69dc362fe8200787245.png',popuptitle:'https://image.xiaoxxx.cn/test/urlGather/20240716bb68561a66509116542602.png',titlewidth:'5.62rem',popupcon:'https://image.xiaoxxx.cn/test/urlGather/20240716bc5e5b8cb643838e887840.png',conwidth:'5.23rem',conheight:'7.17rem'},
                {index:9,url:'https://image.xiaoxxx.cn/test/urlGather/202407158856b9db7870bc21317153.png',popuptitle:'https://image.xiaoxxx.cn/test/urlGather/2024071600196e25b0ae4b52240326.png',titlewidth:'5.6rem',popupcon:'https://image.xiaoxxx.cn/test/urlGather/2024071610b71f05a29da0d7169173.png',conwidth:'5.24rem',conheight:'7.15rem'},
                {index:10,url:'https://image.xiaoxxx.cn/test/urlGather/20240715436136e17f9b7aa1963559.png',popuptitle:'https://image.xiaoxxx.cn/test/urlGather/20240716c2ea31116d63ec8e183644.png',titlewidth:'5.61rem',popupcon:'https://image.xiaoxxx.cn/test/urlGather/202407167f982dac8cde665b607080.png',conwidth:'5.24rem',conheight:'5.61rem'},
                {index:11,url:'https://image.xiaoxxx.cn/test/urlGather/202407152626558b90256bcd686027.png',popuptitle:'https://image.xiaoxxx.cn/test/urlGather/20240716cf42d5324876b136998119.png',titlewidth:'5.61rem',popupcon:'https://image.xiaoxxx.cn/test/urlGather/2024071680a52dc07b85c522874040.png',conwidth:'5.24rem',conheight:'7.21rem'}
            ]
        }
    },
    methods:{
        handleCalendar(data){
            this.itemPopup = data
            this.isCalendarPopup = true
            this.$emit('closeTips', false)
        },
        closeCalendarPopup(){
            this.isCalendarPopup = false
            this.$emit('closeTips', true)
        }
    }
}
</script>
