<style scoped>
    .result{
        width: 100%;
        position: relative;
        overflow: hidden;
    }
    .content{
        width: 100%;
        height: 26rem;
        background: url('https://image.xiaoxxx.cn/test/urlGather/20240715375bc274000f4862501456.jpg') no-repeat center #fcd0d5;
        background-size: contain;
        position: relative;
        box-sizing: border-box;
        padding-top: 0.85rem;
        padding-bottom: 0.5rem;
    }
    .bg{
        width: 7.3rem;
        height: 10.84rem;
        position: absolute;
        right: 0;
        top: -1.85rem;
    }
    .head{
        width: 6.5rem;
        height: 0.38rem;
        background: url('https://image.xiaoxxx.cn/test/urlGather/20240715b8c4017239d57ee9474874.png') no-repeat center;
        background-size: contain;
        margin: 0 auto;
    }
    .result-content{
        width: 7.5rem;
        height: 22.87rem;
        box-sizing: border-box;
        padding-top: 0.8rem;
        background: url('https://image.xiaoxxx.cn/test/urlGather/202407158fc02c582dab77b9912529.png') no-repeat top center;
        background-size: contain;
        position: relative;
    }
    .is-who{
        width: 6.03rem;
        height: 0.82rem;
        background: url('https://image.xiaoxxx.cn/test/urlGather/20240715a1fc386bdb9db6ce130294.png') no-repeat center;
        background-size: contain;
        margin: 0 auto;
    }
    .time{
        width: 6.65rem;
        height: 6.17rem;
        background: url('https://image.xiaoxxx.cn/test/urlGather/20240716adf9264688d8fbb8380890.png') no-repeat center;
        background-size: contain;
        margin: 0.4rem auto 0.4rem;
        position: relative;
    }
    .more-time{
        width: 2.11rem;
        height: 2.31rem;
        background: url('https://image.xiaoxxx.cn/test/urlGather/202407150d60370624b29d79458165.png') no-repeat center;
        background-size: contain;
        position: absolute;
        right: -0.4rem;
        bottom: 0.4rem;
    }
    .business{
        width: 6.2rem;
        height: 0.33rem;
        background: url('https://image.xiaoxxx.cn/test/urlGather/202407156f2c302992e734d2849864.png') no-repeat center;
        background-size: contain;
        margin: 0 auto;
    }
    .vision{
        width: 6.31rem;
        height: 1.66rem;
        background: url('https://image.xiaoxxx.cn/test/urlGather/2024071680a3e9ed9f6ade91706440.png') no-repeat center;
        background-size: contain;
        margin: 0.3rem auto 0.3rem;
    }
    .interaction{
        width: 6.31rem;
        height: 2.08rem;
        background: url('https://image.xiaoxxx.cn/test/urlGather/202407166183c91f085090a7741388.png') no-repeat center;
        background-size: contain;
        margin: 0 auto;
    }
    .case{
        width: 6.31rem;
        height: 1.5rem;
        background: url('https://image.xiaoxxx.cn/test/urlGather/202407160d61ca8063585b76361439.png') no-repeat center;
        background-size: contain;
        margin: 0.3rem auto 0.5rem;
    }
    .more{
        width: 4.47rem;
        height: 0.69rem;
        background: url('https://image.xiaoxxx.cn/test/urlGather/202407164e6dc94fa99e264a981396.png') no-repeat center;
        background-size: contain;
        margin: 0 auto;
        animation: morean 2s linear infinite;
    }
    @keyframes morean{
        0%{transform: scale(1);}
        25%{transform: scale(1.1);}
        50%{transform: scale(1);}
        75%{transform: scale(0.9);}
        100%{transform: scale(1);}
    }
    .cooperate{
        width: 6.03rem;
        height: 1.63rem;
        background: url('https://image.xiaoxxx.cn/test/urlGather/20240715a67915a36efcccdf237357.png') no-repeat center;
        background-size: contain;
        margin: 0.9rem auto 0.3rem;
    }
    .coo-logo{
        width: 5.85rem;
        height: 3.02rem;
        background: url('https://image.xiaoxxx.cn/test/urlGather/20240715358361e20b14c1be500471.png') no-repeat center;
        background-size: contain;
        margin: 0 auto;
    }
    .swiper-container,.swiper-wrapper{
        width: 100%;
    }
    .swiper-slide{
        width: 100%;
        height: auto;
    }
    .code{
        width: 7rem;
        height: 2.85rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0.3rem;
    }
</style>
<template>
    <div class="result main">
        
        <div class="swiper-container main" id="swiper-container2">
            <div class="swiper-wrapper main">
                <div class="swiper-slide">                
                    <div class="content">
                        <img src="https://image.xiaoxxx.cn/test/urlGather/202407154baccf1f414babaf887097.png" alt="" class="bg">
                        <div class="head"></div>
                        <div class="result-content">
                            <div class="is-who"></div>
                            <div class="time">
                                <div class="more-time"></div>
                            </div>
                            <div class="business"></div>
                            <div class="vision"></div>
                            <div class="interaction"></div>
                            <div class="case"></div>
                            <div class="more" @click="nextPage"></div>
                            <div class="cooperate"></div>
                            <div class="coo-logo"></div>
                        </div>
                        <img src="https://image.xiaoxxx.cn/test/urlGather/20240716d9ca6ae6f983326d120413.png" alt="" class="code code-an">
                    </div>
                </div>  
            </div>
            <div class="swiper-scrollbar"></div>
        </div>
    </div>
</template>
<script>
    export default{
        mounted(){
            new window.Swiper('#swiper-container2',{//子swiper
                direction: 'vertical',
                nested:true,
                slidesPerView: 'auto',
                freeMode: true,
                scrollbar: {
                    el: '.swiper-scrollbar',
                },
            })
        },
        methods:{
            nextPage(){
                window.location.href = 'https://www.xiaoxiankj.com/#/'
            }
        }
    }
</script>